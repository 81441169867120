<template>
    <div>
        <div v-if="info">
            <van-cell-group style="display: flex;justify-content:space-between;margin-bottom: 10px;">
                <div style="width: 85%;">
                    <van-cell title="订单编号"
                              :value="info.order_sn"/>
                    <van-cell title="创建时间"
                              :value="info.createtime_text"/>
                </div>
                <div style="width: 15%; background-color: #1ca6ff;text-align: center;line-height: 80px;font-size: 14px;color: white;">
                    {{info.status_text}}
                </div>
            </van-cell-group>

            <van-cell-group style="margin-bottom: 10px;">
                <van-cell
                        title="产品名称"
                        :value="info.goods.moldbaby_name"/>
                <van-cell
                        title="产品零售价"
                        :value="info.goods.retail_price"/>
                <van-cell
                        title="优惠价"
                        :value="info.goods.real_price"/>
                <van-cell
                        title="支付金额"
                        :value="info.deposit_price"/>
            </van-cell-group>

            <van-cell-group style="margin-bottom: 10px;" v-if="info.status==1">
                <van-cell
                        title="爆款核销码"
                        :value="info.code"/>
                <div style="width: 100px;margin: 0 auto;margin-top: 10px;margin-bottom: 10px;">
                    <img style="width: 100%;" :src="info.code_qr"/>
                </div>
            </van-cell-group>
			<div style="padding: 10px;" v-show="info.status == 0">
				<van-button type="info" block="" @click="pay()">立即支付</van-button>
			</div>
            <div class="bottom-tip">
                <div>温馨提示：</div>
                <div>1.如5分钟内未支付，该订单将自动关闭</div>
                <div>2.支付后，请于活动结束前到该品牌所属门店核销</div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        Field,
        Row,
        Col,
        Cell,
        CellGroup,
        Picker,
        Popup,
        DatetimePicker,
        Uploader,
        SwitchCell,
        Checkbox,
        Button,
        Toast,
        Stepper,
        Slider
    } from "vant";
    import {
        user_marketing_hot_orderdetail,
		user_marketing_hot_buildorder,
		live_order_pay
    } from "@/request/api";
	import wx from "@/request/utils/wechat";//微信JSSDK

    export default {
        components: {
            [Field.name]: Field,
            [Row.name]: Row,
            [Col.name]: Col,
            [Cell.name]: Cell,
            [CellGroup.name]: CellGroup,
            [Picker.name]: Picker,
            [Popup.name]: Popup,
            [DatetimePicker.name]: DatetimePicker,
            [Uploader.name]: Uploader,
            [SwitchCell.name]: SwitchCell,
            [Checkbox.name]: Checkbox,
            [Button.name]: Button,
            [Stepper.name]: Stepper,
            [Slider.name]: Slider
        },
        data() {
            return {
                order_id: 0,
                info: false
            }
        },
        mounted: function () {
            this.order_id = this.$route.query.id;
            this.initData();
        },
        methods: {
            initData() {
                user_marketing_hot_orderdetail({rid: this.order_id}).then(res => {
                    if (res.code == 1) {
						res.data.goods = JSON.parse(res.data.goods_info)
                        this.info = res.data;
                    } else {
                        this.$dialog({title: '提示', message: res.msg}).then(g => {
                            this.$router.go(-1);
                        });
                    }
                }).catch(error => {
                    this.$dialog({title: '提示', message: "访问出错"}).then(g => {
                        this.$router.go(-1);
                    });
                });
            },
			pay() {
				if (this.info.status == 0) {
					this.$router.push({
						path: '/payment/method',
						query: {
							id: this.info.id,
							type: 'hot'
						}
					})
				}
			}
        }
    }
</script>

<style scoped lang="less">
    .bottom-tip {
        font-size: 12px;
        padding: 15px;
        line-height: 20px;
        letter-spacing: 1px;
        color: gray;
    }
</style>
